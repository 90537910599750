import FavouriteGenres from "./FavouriteGenres";
import NoSubscriptions from "./NoSubscriptions";
import TilesSlider from "./TilesSlider";
import VideoSection from "./VideoSection";
import WatchEveryWhere from "./WatchEveryWhere";
import WatchEveryWhereTab from "./WatchEveryWhereTab";
import AppInstall from "./AppInstall";
import Footer from "./Footer";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";
import React, { useState } from "react";
import HeroSection from "./HeroSection";
import FAQ from "./FAQ";
import BlogTiles from "./BlogTiles";

function Home() {
  return (
    <React.Fragment>
      <div className="page">
        <AppInstall></AppInstall>
        {/* <VideoSection></VideoSection> */}
        <HeroSection></HeroSection>
        {/* <IntroSection></IntroSection> */}
        <WatchEveryWhereTab></WatchEveryWhereTab>
        <NoSubscriptions></NoSubscriptions>

        <TilesSlider></TilesSlider>
        <BlogTiles></BlogTiles>
        <FavouriteGenres />
        {/* <WatchEveryWhere></WatchEveryWhere> */}
        <FAQ></FAQ>
        <Footer></Footer>
        <UserConsent></UserConsent>
      </div>
      <LoaderAnim id="wanim"></LoaderAnim>
    </React.Fragment>
  );
}

const wait = (delay = 0) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) => {
  let element =
    typeof elementOrSelector === "string"
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;
  if (element) {
    element.style.display = visible ? "block" : "none";
  }
  /*(typeof elementOrSelector === "string"
    ? document.querySelector(elementOrSelector)
    : elementOrSelector
  ).style.display = visible ? "block" : "none";*/
};

wait(1).then(() => {
  setVisible(".page", false);
  setVisible("#loading", true);
  /*try {
    setVisible(".page", false);
  } catch (error) {}
  try {
    setVisible("#loading", true);
  } catch (error) {}*/
});

document.addEventListener("DOMContentLoaded", () =>
  wait(2000).then(() => {
    setVisible(".page", true);
    setVisible("#loading", false);
  })
);
export default Home;
