import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
function BlogDetailsPage(data) {
  let listData = data.data;
  /*const { id } = useParams();
  const [listData, setListData] = useState({});
  useEffect(() => {
    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const getDetails = myJson.filter((val) => val.id == id);
        setListData(getDetails[0]);
      });
  }, []);*/

  return (
    <section className="container blog-posts">
      <div className="row mt-2">
        <div className="blogstuff-de">
          <p>{listData?.description}</p>
        </div>
        {Object.keys(listData).length > 0 &&
          listData.details.map((item, index) => {
            return [
              index % 2 === 0 ? (
                <article className="blog-de-post" key={index}>
                  <div className="blog-de-post-container row">
                    <div className="blog-de-post-image col-md-3 col-sm-12">
                      <img src={item.movie_image} alt="Blog Post Image" />
                    </div>
                    <div className="blog-de-post-content col-md-9 col-sm-12">
                      <div className="blog-de-post-header">
                        <h2>{item.movie_title}</h2>
                        {/* <div className="blog-de-post-meta">
              <span className="date">{item.movie_title}</span>
            </div> */}
                      </div>
                      <div className="blogstuff-de">
                        <p>{item.movie_description}</p>
                      </div>
                    </div>
                  </div>
                </article>
              ) : (
                <article className="blog-de-post" key={index}>
                  <div className="blog-de-post-container row">
                    <div className="blog-de-post-content col-md-9 col-sm-12">
                      <div className="blog-de-post-header">
                        <h2>{item.movie_title}</h2>
                        {/* <div className="blog-de-post-meta">
              <span className="date">Action, Comedy, Crime</span>
            </div> */}
                      </div>
                      <div className="blogstuff-de">
                        <p>{item.movie_description}</p>
                      </div>
                    </div>
                    <div className="blog-de-post-image col-md-3 col-sm-12">
                      <img src={item.movie_image} alt="Blog Post Image" />
                    </div>
                  </div>
                </article>
              ),
            ];
          })}
        <div className="blogstuff-de">
          <div dangerouslySetInnerHTML={{ __html: listData?.footer_title }} />
        </div>
      </div>
    </section>
  );
}
export default BlogDetailsPage;
