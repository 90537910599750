import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import BlogDetailsHero from "./BlogDetailsHero";
import BlogDetailsPage from "./BlogDetailsPage";
import AppInstall from "./AppInstall";
import Footer from "./Footer";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";

function BlogInner() {
  const { id } = useParams();
  const [listData, setListData] = useState({});
  useEffect(() => {
    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const getDetails = myJson.filter((val) => val.id == id);
        setListData(getDetails[0]);
        let pageTitle = getDetails[0].title;
        let pageDescription =
          getDetails[0].meta_description + getDetails[0].meta_keywords;
        let pageKeywords = getDetails[0].meta_keywords;

        const metaOgTitle = document.querySelector('meta[property="og:title"]');
        const metaTwitterTitle = document.querySelector(
          'meta[name="twitter:title"]'
        );

        document.title = pageTitle;
        if (metaOgTitle) {
          metaOgTitle.setAttribute("content", pageTitle);
        }
        if (metaTwitterTitle) {
          metaTwitterTitle.setAttribute("content", pageTitle);
        }
        const updateMetaTag = (name, content) => {
          const metaTag = document.querySelector(
            `meta[name="${name}"], meta[property="${name}"]`
          );
          if (metaTag) {
            metaTag.setAttribute("content", content);
          }
        };

        updateMetaTag("description", pageDescription);
        updateMetaTag("og:description", pageDescription);
        updateMetaTag("twitter:description", pageDescription);
        updateMetaTag("keywords", pageKeywords);
      });
  }, []);
  return (
    <React.Fragment>
      {/* <div className="page"> */}
      <AppInstall></AppInstall>
      {listData && listData.id ? <BlogDetailsHero data={listData} /> : ""}
      {listData && listData.id ? <BlogDetailsPage data={listData} /> : ""}
      {/* <BlogDetailsPage data={listData} /> */}
      <Footer></Footer>
      <UserConsent></UserConsent>
      {/* </div> */}
      {/* <LoaderAnim id="wanim"></LoaderAnim> */}
    </React.Fragment>
  );
}

// const wait = (delay = 0) =>
//   new Promise((resolve) => setTimeout(resolve, delay));

// const setVisible = (elementOrSelector, visible) => {
//   let element =
//     typeof elementOrSelector === "string"
//       ? document.querySelector(elementOrSelector)
//       : elementOrSelector;
//   if (element) {
//     element.style.display = visible ? "block" : "none";
//   }
// };

// wait(1).then(() => {
//   setVisible(".page", false);
//   setVisible("#loading", true);
// });

// document.addEventListener("DOMContentLoaded", () =>
//   wait(2000).then(() => {
//     setVisible(".page", true);
//     setVisible("#loading", false);
//   })
// );

export default BlogInner;
