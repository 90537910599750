import React, { useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
function FAQ() {
  const [activeKey, setActiveKey] = useState();
  function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      handleClick();
    });

    return (
      <div className="faq-card-header" type="button" onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }
  const data = [
    {
      name: "Q1. Is Fawesome really free?",
      answer:
        "Today and always! Fawesome is a free video streaming app. We include ads to monetize content provided by our partners so that we can continue to keep our services free for you.",
    },
    {
      name: "Q2. Where is Fawesome available?",
      answer:
        "Fawesome is available on all major OTT platforms and streaming devices, including Roku, Fire TV, Apple TV, all Smart TV’s, iOS and Android devices.",
    },
    {
      name: "Q3. Where can I watch Fawesome?",
      answer:
        "Watch free movies and TV shows on your TV, smartphone, or tablet using the Fawesome app or watch movies free online on the Fawesome website anytime, anywhere.",
    },
    {
      name: "Q4. Is Fawesome legal?",
      answer:
        "Yes, Fawesome is 100% legal ad-supported legal service that doesn’t require any subscription or sign-up. Stream free movies online hassle-free. ",
    },
    {
      name: "Q5. How many free movies & TV shows are available on Fawesome?",
      answer:
        "Fawesome offers a vast collection of 173,000 popular movies, comedy specials, documentaries and television shows, spanning all genres- family, comedies, true crime, drama, westerns, reality and classic",
    },
  ];
  return (
    <section id="noSubInStr" className="padVer">
      <div className="container">
        <div className="row">
          <h2>FAQS</h2>
        </div>
        <div className="row mt-5">
          <Accordion className="faq-accordion">
            {data.map((item, index) => (
              <Card key={index} className="faq-card">
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  {item.name}
                  <span className="faq-ex-cl">
                    {activeKey === index ? "-" : "+"}
                  </span>
                </CustomToggle>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body className="faq-card-body">{item.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}
export default FAQ;
