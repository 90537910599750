import React, { useEffect, useState, useRef } from "react";

import Pagination from "react-bootstrap/Pagination";
function BlogList() {
  const ref = useRef(null);
  const [state, setState] = useState({
    data: [],
    limit: 10,
  });
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setListData(myJson);
        const out = myJson.slice(0, limit);
        setState((prev) => ({
          ...prev,
          data: out,
        }));
        //setListData(out);
        //console.log('listout---',listData, out)
        //console.log("listout---", Math.round(listData.length / limit));
      });
  }, []);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const offset = pageNumber * limit;
    const out = listData.slice(offset - limit, offset);
    setState((prev) => ({
      ...prev,
      data: out,
    }));
    ref.current?.scrollIntoView({ behavior: "smooth" });
    //setState((prev) => ({ ...prev, activePage: pageNumber }));
  };
  return (
    <section className="container blog-posts" ref={ref}>
      <div className="row mt-2">
        {state.data.length > 0 &&
          state.data.map((item, index) => {
            return [
              index % 2 === 0 ? (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt="Blog Post Image" />
                    </div>
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <span className="date">{item.year}</span>
                        </div>
                      </div>
                      <p>{item.description}</p>
                      <a
                        // href={`./blogs/${item.id}`}
                        href={`./#/blogs/${item.id}`}
                        className="btn btn-primary"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </article>
              ) : (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <span className="date">{item.year}</span>
                        </div>
                      </div>
                      <p>{item.description}</p>
                      <a
                        href={`./#/blogs/${item.id}`}
                        className="btn btn-primary"
                      >
                        READ MORE
                      </a>
                    </div>
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt="Blog Post Image" />
                    </div>
                  </div>
                </article>
              ),
            ];
          })}
        {/* <article className="blog-post">
            <div className="blog-post-container row">
              <div className="blog-post-content col-md-8 col-sm-12">
                <div className="blog-post-header">
                  <h2>Movie Night Sorted: 10 Free Must-Watch Films on Fawesome in June</h2>
                  <div className="blog-post-meta">
                    <span className="date">June 1, 2024</span>
                  </div>
                </div>
                <p>June is here, and it's time to refresh your watchlist with some incredible films that you can watch for free on Fawesome.</p>
                <a href="#" className="btn btn-primary">READ MORE</a>
              </div>
              <div className="blog-post-image col-md-4 col-sm-12">
                <img src="./assets/images/blog-2.png" alt="Blog Post Image" />
              </div>
            </div>
          </article> */}
      </div>
      {Array(Math.round(listData.length / limit)).length > 0 && (
        <Pagination className="px-4 d-flex justify-content-center">
          <Pagination.Prev
            disabled={activePage > 1 ? false : true}
            onClick={() => handlePageChange(activePage - 1)}
          />
          {[...Array(Math.round(listData.length / limit))].map((_, index) => {
            return (
              <Pagination.Item
                onClick={() => handlePageChange(index + 1)}
                key={index + 1}
                active={index + 1 === activePage}
              >
                {index + 1}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            disabled={
              activePage < Math.round(listData.length / limit) ? false : true
            }
            onClick={() => handlePageChange(activePage + 1)}
          />
        </Pagination>
      )}
    </section>
  );
}
export default BlogList;
