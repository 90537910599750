import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Tile from "./Tile";

function TilesSlider() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("./assets/data/genres-items.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  }, []);
  return (
    <section id="titles">
      <div className="container">
        <div className="row text-center m-5">
          <h2>SELECT FROM YOUR FAVORITE GENRES</h2>
        </div>
        
          <div className="titles-slide">
            {!data ? "" : getSliderItems(data)}
          </div>
        </div>
    </section>
  );
}

function getSliderItems(data) {
  let settings = {
    dots: false,
    // lazyLoad: "ondemand",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    focusOnSelect: true,
    autoplay: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data.map((item, key) => {
        return item.nodeId ? <Tile item={item} key={key} /> : "";
      })}
    </Slider>
  );
}
export default TilesSlider;
