import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
function BlogDetailsHero(data) {
  let listData = data.data;
  // const { id } = useParams();
  // const [listData, setListData] = useState({});
  // useEffect(() => {
  //   fetch("./assets/data/blogs.json", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (myJson) {
  //       const getDetails = myJson.filter((val) => val.id == id);
  //       setListData(getDetails[0]);
  //     });
  // }, []);
  let heroImage = "./assets/images/heroImage.jpg";
  let logoFawesome = "./assets/images/logo-fawesome.svg";
  let searchIcon = "./assets/images/search.svg";
  let browseIcon = "./assets/images/browse.svg";
  let debounceTimer;
  const [inputValue, setInputValue] = useState("");

  const menuInfo = [
    { name: "Home", link: "https://fawesome.tv/home/" },
    { name: "Collections", link: "https://fawesome.tv/categories/collections" },
    { name: "Shows", link: "https://fawesome.tv/categories/shows" },
    { name: "Best Movies", link: "https://fawesome.tv/categories/best-movies" },
    { name: "Recommended", link: "https://fawesome.tv/categories/recommended" },
    { name: "Search", link: "https://fawesome.tv/home/" },
    // { name: "About", link: "https://fawesome.tv/home/" },
  ];
  return (
    <>
      <Navbar className="bg-body-tertiary navbar-dark" expand="xl">
        <div className="container-fluid">
          <Navbar.Brand href="./">
            {/* <a
              className="navbar-brand"
              rel="noopener noreferrer"
              onClick={handleClick}
            > */}
            <img
              src={logoFawesome}
              className="img-fluid fw-logo"
              alt="Fawesome"
              // style="opacity: 1;"
            />
            {/* </a> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Collections</Nav.Link>
            <Nav.Link href="#link">Shows</Nav.Link>
            <Nav.Link href="#link">Best Movies</Nav.Link>
            <Nav.Link href="#link">Recommended</Nav.Link> */}
            {getNamedMenu()}
            {/* <Nav.Link>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    value=""
                    id="search-input"
                    placeholder="Search"
                  />
                </div>
              </div>
            </Nav.Link> */}

            {/* <Nav.Link href="#link">About</Nav.Link> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="row col-md-12 col-sm-12">
        <div className="col-md-6 col-sm-12 de-mob-image">
          <img src={listData?.image} className="heropic-de" alt="fawesome" />
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="fCorLeftDe"></div>

          <div className="herotitleContDe">
            <p className="cta-intro-de">{listData?.year}</p>
            <h1 className="herotitle-de">{listData?.title}</h1>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 de-image">
          <img src={listData?.image} className="heropic-de" alt="fawesome" />
        </div>
      </div>
    </>
  );

  function handleClick() {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    var newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    var homePath = "/home/";
    if (newUrl.endsWith("/")) {
      homePath = "home/";
    }
    window.location.href = newUrl + homePath + qs;
  }

  function getNamedMenu() {
    return (
      <>
        {menuInfo.map((item, index) =>
          item.name == "Search" ? (
            <Nav.Link key={index}>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    id="search-input"
                    placeholder="Search"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </Nav.Link>
          ) : (
            <Nav.Link href={item.link} key={index}>
              {item.name}
            </Nav.Link>
          )
        )}
      </>
    );
  }

  function handleChange(event) {
    var searchText = event.target.value;
    setInputValue(event.target.value);
    // Set debounce delay in milliseconds
    var debounceDelay = 2000;
    // Check if searchText has at least 2 letters
    if (searchText.length >= 2) {
      // Clear previous debounce timer
      clearTimeout(debounceTimer);
      // Set new debounce timer
      debounceTimer = setTimeout(function () {
        window.location.href = `https://fawesome.tv/search/${searchText}`;
      }, debounceDelay);
    }
  }

  function handleKeyDown(event) {
    var searchText = event.target.value;
    clearTimeout(debounceTimer);
    if (event.key === "Enter") {
      if (searchText.length >= 2) {
        window.location.href = `https://fawesome.tv/search/${searchText}`;
      }
    }
  }
}
export default BlogDetailsHero;
