import React, { useState, useEffect } from "react";

function BlogTiles() {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setListData(myJson);
      });
  }, []);
  return (
    <React.Fragment>
      {listData && listData.length > 0 ? getTiles(listData) : ""}
    </React.Fragment>
  );
}
const getTiles = (items) => {
  return (
    <section id="watchblogtiles" className="padVerTab">
      <div className="container-fluid row col-md-12 d-flex justify-content-center web-view">
        {items.slice(0, 3).map((item, index) => (
          <article className="blog-tiles col-md-3" key={index}>
            <div className="blog-tiles-container">
              <div className="blog-tiles-image col-md-12 col-sm-12">
                <img src={item.image} alt="Blog Post Image" />
              </div>
              <div className="blog-tiles-content col-md-12 col-sm-12">
                <div className="blog-tiles-meta">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#4f4f8d"
                    className="bi bi-calendar-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                  &nbsp;&nbsp;
                  <span className="date">{item.year}</span>
                  <hr />
                </div>
                <div className="blog-tiles-header">
                  <h2>{item.title}</h2>
                </div>
                <p>{item.description}</p>
                <a
                  // href={`./blogs/${item.id}`}
                  href={`./#/blogs/${item.id}`}
                  className="btn btn-primary read-more-btn"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </article>
        ))}
        <div className="text-center show-all">
          <a href="./#/blogs/" rel="" className="blog-link">
            <p>
              Go to blog page
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </p>
          </a>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-center mobile-view d-none">
        {items.slice(0, 1).map((item, index) => (
          <article className="blog-tiles col" key={index}>
            <div className="blog-tiles-container">
              <div className="blog-tiles-image col-md-12 col-sm-12">
                <img src={item.image} alt="Blog Post Image" />
              </div>
              <div className="blog-tiles-content col-md-12 col-sm-12">
                <div className="blog-tiles-meta">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#4f4f8d"
                    className="bi bi-calendar-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                  &nbsp;&nbsp;
                  <span className="date">{item.year}</span>
                  <hr />
                </div>
                <div className="blog-tiles-header">
                  <h2>{item.title}</h2>
                </div>
                <p>{item.description}</p>
                <a
                  // href={`./blogs/${item.id}`}
                  href={`./#/blogs/${item.id}`}
                  className="btn btn-primary read-more-btn"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </article>
        ))}
        <div className="text-center show-all">
          <a href="./#/blogs/" rel="" className="blog-link">
            <p>
              Go to blog page
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogTiles;
